import $ from 'jquery';
import 'highcharts';
import 'bootstrap';
let listChecked = [];
let listPieChecked = [];

$(document).on('ready turbolinks:load', function () {
  var lineObjectType = $('.js-source-line-selector-ajax').val();
  var pieObjectType = $('.js-source-pie-selector-ajax').val();
  fillLineListSubData(lineObjectType, 'line');
  fillLineListSubData(pieObjectType, 'pie');

  $.fn.datepicker.dates['ja'] = {
    days: ["日", "月", "火", "水", "木", "金", "土"],
    daysShort: ["日", "月", "火", "水", "木", "金", "土"],
    daysMin: ["日", "月", "火", "水", "木", "金", "土"],
    months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    format: "yyyy年mm月",
    weekStart: 0,
    maxDate: '0'
  };

  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth();
  var current_date = date.getDate();

  initDatePickerSingleDate(year, month, current_date);
  initDatePickerSingleMonth(year, month);
  initDatePickerSingleYear(year, month);

  $(document.body).on("change", ".js-select-report-time-type", function () {
    let report_time_type = $(this).val();

    let chart_options = $('.js-report-filter-area').find('.line-chart');
    for (let i = 0; i < chart_options.length; i++) {
      $(chart_options[i]).addClass('d-none');
      $(chart_options[i]).find('input').val("");
    }

    if (report_time_type == '日') {
      $('.js-report-filter-area').find('.line-chart.js-line-day-and-week-report').removeClass('d-none');
      $('.js-alert-select-date-too-long-modal').find('.modal-body').html("最大31日間選択できます。もう一度選択してください。");
    } else if (report_time_type == '週') {
      $('.js-report-filter-area').find('.line-chart.js-line-day-and-week-report').removeClass('d-none');
      $('.js-alert-select-date-too-long-modal').find('.modal-body').html("最大31週間選択できます。もう一度選択してください。");
    } else if (report_time_type == '月') {
      $('.js-report-filter-area').find('.line-chart.js-line-months-report').removeClass('d-none');
      $('.js-alert-select-date-too-long-modal').find('.modal-body').html("最大36ヶ月選択できます。もう一度選択してください。");
    } else if (report_time_type == '年') {
      $('.js-report-filter-area').find('.line-chart.js-line-years-report').removeClass('d-none');
    }

    $('#line-chart-report').html("");
  });

  $('.js-source-line-selector-ajax').on('select2:select', function (e) {
    fillLineListSubData(e.params.data.id, 'line');
  });

  $('.js-source-pie-selector-ajax').on('select2:select', function (e) {
    fillLineListSubData(e.params.data.id, 'pie');
  });

  $('.js-sub-selector-modal-btn').on('click', function (e) {
    e.preventDefault();

    $('.js-sub-selector-modal').modal('show');
  });

  $('.js-sub-pie-selector-modal-btn').on('click', function (e) {
    e.preventDefault();

    $('.js-sub-pie-selector-modal').modal('show');
  });

  $(document).on('change', '.js-check-all', function (e) {
    $(this).closest('.modal').find('.object-item').prop('checked', $(this).prop('checked')).change();
  });

  $('.js-btn-cancel-select').on('click', function (e) {
    if($(this).closest('.modal').hasClass('js-sub-selector-modal')) {
      $('.js-sub-selector-modal .object-item').prop('checked', false).change();
      $.each(listChecked, function(index, value) {
        $('.js-sub-selector-modal .object-item[value="' + value + '"]').prop('checked', true).change();
      });
    } else {
      $('.js-sub-pie-selector-modal .object-item').prop('checked', false).change();
      $.each(listPieChecked, function(index, value) {
        $('.js-sub-pie-selector-modal .object-item[value="' + value + '"]').prop('checked', true).change();
      });
    }
  });

  $('.js-btn-submit-select').on('click', function (e) {
    if($(this).closest('.modal').hasClass('js-sub-selector-modal')) {
      setListChecked('line');
    } else {
      setListChecked('pie');
    }
  });

  $(document).on('change', '.object-item', function(){
    let parent = $(this).closest("div.row");

    if(this.checked){
      parent.find('.js-object-name').addClass('checked-text');
    } else {
      parent.find('.js-object-name').removeClass('checked-text');
    }

    let modal = $(this).closest('.modal');
    if($(modal).find('.object-item').length == $(modal).find('.object-item:checked').length) {
      $(modal).find('.js-check-all').prop('checked', true);
    } else {
      $(modal).find('.js-check-all').prop('checked', false);
    }
  });

  $('.js-return-btn-dismiss').on('click', function () {
    $(this).closest('.js-sub-selector-modal').find('.js-sub-selector-search-by-name').val('').trigger('keyup');
    $(this).closest('.js-sub-pie-selector-modal').find('.js-sub-selector-search-by-name').val('').trigger('keyup');
  });

  $(".line-chart-submit-btn").on('click', function (e) {
    e.preventDefault();

    var objectType = $('.js-source-line-selector-ajax').val();
    var objectSelected = [];
    $(".js-sub-selector-modal .object-item:checked").each(function () {
      objectSelected.push($(this).val());
    });

    let report_time_type = $('.js-select-report-time-type').val();
    let time_range_area = null;

    if (report_time_type == '日' || report_time_type == '週') {
      time_range_area = $('.js-line-day-and-week-report');
    } else if (report_time_type == '月') {
      time_range_area = $('.js-line-months-report');
    } else if (report_time_type == '年') {
      time_range_area = $('.js-line-years-report');
    }

    let start_at = $(time_range_area).find('input[name="start_at"]').val();
    let end_at = $(time_range_area).find('input[name="end_at"]').val();

    if (start_at == "" || end_at == "") {
      $('.js-alert-choose-start-end-at-modal').modal('show');
    } else if(objectSelected.length == 0) {
      $('.js-alert-empty-object-selected-modal').modal('show');
    } else {
      $.ajax({
        url: '/reports/line_charts',
        method: 'POST',
        data: {
          object_type: objectType,
          object_selected: objectSelected,
          report_time_type: report_time_type,
          start_at: start_at,
          end_at: end_at,
          'authenticity_token': $('meta[name="csrf-token"]').attr('content')
        }
      }).done(function (response) {
        if (response.data.length > 0) {
          drawLineCharts(response.categories, response.data);
        }
      });
    }
  })

  $(".pie-chart-submit-btn").on('click', function (e) {
    e.preventDefault();

    var objectType = $('.js-source-pie-selector-ajax').val();
    var objectText = $('.js-source-pie-selector-ajax').text();
    var objectSelected = [];
    $(".js-sub-pie-selector-modal .object-item:checked").each(function () {
      objectSelected.push($(this).val());
    });

    let start_at = $('.js-pie-day-and-week-report').find('input[name="start_at"]').val();
    let end_at = $('.js-pie-day-and-week-report').find('input[name="end_at"]').val();

    if (start_at == "" || end_at == "") {
      $('.js-alert-choose-start-end-at-modal').modal('show');
    } else {
      $.ajax({
        url: '/reports/pie_charts',
        method: 'POST',
        data: {
          object_type: objectType,
          object_selected: objectSelected,
          start_at: start_at,
          end_at: end_at,
          'authenticity_token': $('meta[name="csrf-token"]').attr('content')
        }
      }).done(function (response) {
        if (response.data.length > 0) {
          drawPieCharts(response.data, objectText);
        }
      });
    }
  })
});

function initDatePickerSingleDate(year, month, current_date) {
  $('input.datepicker-singledate').datepicker({
    format: 'yyyy年mm月dd日',
    language: "ja",
    autoclose: true,
    endDate: new Date(year, month, current_date)
  }).on('changeDate', function(e) {
    let select_time_type = $('.js-select-report-time-type').val();

    if ($(e.currentTarget).attr('name') == 'start_at') {
      let selected_end_date = $('input[name="end_at"].datepicker-singledate').val();
      if (selected_end_date != "") {
        let tmp_end_date = selected_end_date.replace("年", "/").replace("月", "/").replace("日", "");
        let tmp_end_date_arr = tmp_end_date.split("/");
        let selected_end_date_full = new Date(parseInt(tmp_end_date_arr[0]), parseInt(tmp_end_date_arr[1]) - 1, parseInt(tmp_end_date_arr[2]));
        let total_days = (selected_end_date_full - e.date)/(1000 * 3600 * 24);
        var max_days = 31;

        if (select_time_type == "週") {
          var max_days = 217;
        }

        if (total_days > max_days) {
          console.log('exceed max date');
          $('.js-alert-select-date-too-long-modal').modal('show');
          $(e.currentTarget).val("");
        }
      }
    } else if ($(e.currentTarget).attr('name') == 'end_at') {
      let selected_start_date = $('input[name="start_at"].datepicker-singledate').val();
      if (selected_start_date != "") {
        let tmp_start_date = selected_start_date.replace("年", "/").replace("月", "/").replace("日", "");
        let tmp_start_date_arr = tmp_start_date.split("/");
        let selected_start_date_full = new Date(parseInt(tmp_start_date_arr[0]), parseInt(tmp_start_date_arr[1]) - 1, parseInt(tmp_start_date_arr[2]));
        let total_days = (e.date - selected_start_date_full)/(1000 * 3600 * 24);
        var max_days = 31;

        if (select_time_type == "週") {
          var max_days = 217;
        }

        if (total_days > max_days) {
          console.log('exceed max date');
          $('.js-alert-select-date-too-long-modal').modal('show');
          $(e.currentTarget).val("");
        }
      }
    }
  });
}

function initDatePickerSingleMonth(year, month) {
  $('input.datepicker-singlemonth').datepicker({
    format: 'yyyy年mm月',
    startView: "months",
    minViewMode: "months",
    language: "ja",
    months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    changeMonth: true,
    changeYear: true,
    autoclose: true,
    endDate: new Date(year, month)
  }).on('changeDate', function(e) {
    if ($(e.currentTarget).attr('name') == 'start_at') {
      let selected_end_date = $('input[name="end_at"].datepicker-singlemonth').val();
      if (selected_end_date != "") {
        let tmp_end_date = selected_end_date.replace("年", "/").replace("月", "");
        let tmp_end_date_arr = tmp_end_date.split("/");
        let selected_end_date_full = new Date(parseInt(tmp_end_date_arr[0]), parseInt(tmp_end_date_arr[1]) - 1);
        let max_end_date = new Date(e.date.getFullYear() + 3, e.date.getMonth(), e.date.getDate());

        if (max_end_date < selected_end_date_full) {
          console.log('exceed max date');
          $('.js-alert-select-date-too-long-modal').modal('show');
          $(e.currentTarget).val("");
        }
      }
    } else if ($(e.currentTarget).attr('name') == 'end_at') {
      let selected_start_date = $('input[name="start_at"].datepicker-singlemonth').val();
      if (selected_start_date != "") {
        let tmp_start_date = selected_start_date.replace("年", "/").replace("月", "");
        let tmp_start_date_arr = tmp_start_date.split("/");
        let selected_start_date_full = new Date(parseInt(tmp_start_date_arr[0]), parseInt(tmp_start_date_arr[1]) - 1);
        let max_start_date = new Date(e.date.getFullYear() - 3, e.date.getMonth(), e.date.getDate());

        if (max_start_date > selected_start_date_full) {
          console.log('exceed max date');
          $('.js-alert-select-date-too-long-modal').modal('show');
          $(e.currentTarget).val("");
        }
      }
    }
  });
}

function initDatePickerSingleYear(year, month) {
  $("input.datepicker-singleyear").datepicker({
    format: "yyyy",
    startView: "years",
    minViewMode: "years",
    language: "ja",
    autoclose: true,
    endDate: new Date(year, month)
  });
}

function fillLineListSubData(objectType, chartType) {
  $.ajax({
    url: '/reports/get_list_by_type',
    method: 'GET',
    data: {
      object_type: objectType,
      'authenticity_token': $('meta[name="csrf-token"]').attr('content')
    }
  }).done(function (response) {
    var lists = response.data;
    if (chartType == 'line') {
      $(".js-sub-selector-modal .sub-item").remove();
    } else {
      $(".js-sub-pie-selector-modal .sub-item").remove();
    }

    var str = "";
    $.each(lists, function (i, data) {
      str += `<tr class="sub-item" data-item="${data.name}">
                <td>
                  <div class="row">
                    <div class="col-10 label d-margin-auto js-object-name checked-text">
                      ${data.name}
                    </div>
                    <div class="col-2 text-center">
                      <input type="checkbox" class="object-item" value="${data.id}" />
                    </div>
                  </div>
                </td>
              </tr>`
    });

    if (chartType == 'line') {
      $(".js-sub-selector-modal #sub-selector").append(str);
    } else {
      $(".js-sub-pie-selector-modal #sub-pie-selector").append(str);
    }
    setListChecked(chartType);
  });
}

function setListChecked(chartType) {
  if(chartType == 'line') {
    listChecked = [];

    $('.js-sub-selector-modal .object-item:checked').each(function() {
      listChecked.push(this.value);
    });
  } else {
    listPieChecked = [];

    $('.js-sub-pie-selector-modal .object-item:checked').each(function() {
      listPieChecked.push(this.value);
    });
  }
}

function drawLineCharts(categories, data) {
  if ($("#line-chart-report").length > 0) {
    Highcharts.chart('line-chart-report', {
      title: false,
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            radius: 8
          },
          pointWidth: 20
        }
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        min: 0,
        title: false,
        height: '100%',
        labels: {
          formatter: function () {
            return this.value / 10000 + '万円';
          }
        },
        lineColor: '#dee2e6',
        lineWidth: 1
      },
      legend: {
        title: {
          text: '<span style="font-size: 15px; font-weight: normal; margin: 0px auto;">参考</span>'
        },
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      series: data
    });
  }
}

function drawPieCharts(pieChartData, objectText) {
  if ($("#pie-chart-report").length > 0) {
    Highcharts.chart('pie-chart-report', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: false,
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.percentage:.1f} %</b>',
            distance: -50,
          },
          showInLegend: true
        }
      },
      legend: {
        title: {
          text: '<span style="font-size: 15px; font-weight: normal; margin: 0px auto;">参考</span>'
        },
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      series: [{
        name: objectText,
        data: pieChartData
      }]
    });
  }
}
