import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

$(document).on('ready turbolinks:load', function () {
  var materialType = $('.js-select-material-raw-type').val();
  checkMaterial(materialType);

  $('.js-select-material-raw-type').on('select2:select', function (e) {
    var data = e.params.data;

    checkMaterial(data.id)
  });
});

function checkMaterial(materialType){
  if (materialType == "additive") {
    $(".js-volume-ml-box").removeClass("d-none");
    $('.js-label-volume-txt').text('1個あたりの内容量(g)');
    $('.js-volume-ml').attr("placeholder", "1個あたりの内容量");
  } else if (materialType == "powder") {
    $(".js-volume-ml-box").removeClass("d-none");
    $('.js-label-volume-txt').text('1袋あたりの内容量(kg)');
    $('.js-volume-ml').attr("placeholder", "1袋あたりの内容量");
  } else {
    $(".js-volume-ml-box").addClass("d-none");
    // $("#material_volume_ml").val("");
  }
}
