import $ from 'jquery'

$(document).on('click', '.js-route-save-btn', function (e) {
  e.preventDefault();

  let $form = $(this).parents('form');

  var added_shops = $($form).find('.js-source-shops:visible');
  var is_empty_shop = false;

  for (let i = 0; i < added_shops.length; i++) {
    var shop_id = $(added_shops[i]).val();
    if (shop_id == '' || shop_id == undefined) {
      is_empty_shop = true;
    }
  }

  if (is_empty_shop) {
    $('.js-confirm-not-fill-all-shops-modal').modal('show');
  } else {
    $(this).parents('form').submit();
  }
});

$(document).on('hidden.bs.modal', '.js-confirm-not-fill-all-shops-modal', function() {
  $('.js-source-shops').select2({
    placeholder: "お店",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    }
  });
});
