import $ from 'jquery'

$(document).on('turbolinks:load', function () {
  $(".shop-products-sortable").sortable({
    items: ".product-item:not(.unsortable)",
    update: function (event, ui) {
      let sortable_wrapper = ui.item.parents('.shop-products-sortable');
      let items = sortable_wrapper.find('.product-item:not([style*="display: none"])');

      for (let i = 0; i < items.length; i++) {
        let item_index = i + 1;

        $(items[i]).find('input.js-sort-index-val').val(item_index);
      }
    }
  });

  let checked_shop = localStorage.getItem("checked_shop");
  if (checked_shop) {
    if (checked_shop == 'check_all') {
      $('input.js-checked-shop-ids').val('check_all');

      var checked_shops = $('input[name=checkbox-shop]');
      for (let i = 0; i < checked_shops.length; i++) {
        $(checked_shops[i]).prop('checked', true);
      }
    } else {
      $('input.js-checked-shop-ids').val(checked_shop);

      let checked_shop_ids = checked_shop.split(",");
      var checked_shops = $('input[name=checkbox-shop]');
      for (let i = 0; i < checked_shops.length; i++) {
        if (checked_shop_ids.includes($(checked_shops[i]).val())) {
          $(checked_shops[i]).prop('checked', true);
        }
      }
    }
  }

  $('.js-checkbox-shop').change(function() {
    var is_checked_all = false;

    if(this.checked) {
      if ($(this).val() == "check_all") {
        is_checked_all = true;

        var checked_shops = $('input[name=checkbox-shop]');
        for (let i = 0; i < checked_shops.length; i++) {
          $(checked_shops[i]).prop('checked', true);
        }
      } else {
        var current_shop_id = $(this).val();
        var shop_ids_storage = localStorage.getItem("checked_shop");
        var arr_shop_ids_storage = [];

        if (shop_ids_storage != 'check_all') {
          if (shop_ids_storage) {
            arr_shop_ids_storage = shop_ids_storage.split(",");
          }
          arr_shop_ids_storage.push(current_shop_id);
          localStorage.setItem("checked_shop", arr_shop_ids_storage.join(","));
        }
      }
    } else {
      if ($(this).val() == "check_all") {
        is_checked_all = false;
        localStorage.setItem("checked_shop", "");

        var checked_shops = $('input[name=checkbox-shop]');
        for (let i = 0; i < checked_shops.length; i++) {
          $(checked_shops[i]).prop('checked', false);
        }
      } else {
        var current_shop_id = $(this).val();
        var shop_ids_storage = localStorage.getItem("checked_shop");

        if (shop_ids_storage != 'check_all') {
          var arr_shop_ids_storage = shop_ids_storage.split(",");
          let index = arr_shop_ids_storage.indexOf(current_shop_id);

          if (index > -1) { // only splice array when item is found
            arr_shop_ids_storage.splice(index, 1); // 2nd parameter means remove one item only
          }

          localStorage.setItem("checked_shop", arr_shop_ids_storage.join(","));
        }
      }
    }

    if (is_checked_all) {
      $('input.js-checked-shop-ids').val('check_all');
      localStorage.setItem("checked_shop", "check_all");
    } else {
      var shop_ids_storage = localStorage.getItem("checked_shop");

      $('input.js-checked-shop-ids').val(shop_ids_storage);
    }
  });

  $('#shop-products')
    .on('cocoon:after-insert', function (e, added_item) {
      added_item.after($('#js-link-to-add-item'));

      let sortable_wrapper = $(added_item).parents('.shop-products-sortable');
      let items = sortable_wrapper.find('.product-item:not([style*="display: none"])');

      let added_item_index = items.length;

      $(added_item).find('input.js-sort-index-val').val(added_item_index);
    });

  $('#admin_emails-nested-form .content-nested-form')
    .on('cocoon:after-insert', function (e, added_item) {
      added_item.after($('#js-link-to-add-item'));

      let existed_items = $('#admin_emails-nested-form .content-nested-form').find('.row.item:not([style*="display: none"])');

      $(added_item).find('.js-label-text').html('管理者のメールアドレス' + existed_items.length);
    }).on("cocoon:after-remove", function (e, removed_item) {
      let existed_items = $('#admin_emails-nested-form .content-nested-form').find('.row.item:not([style*="display: none"])');

      for (let i = 0; i < existed_items.length; i++) {
        let item_index = i + 1;

        $(existed_items[i]).find('.js-label-text').html('管理者のメールアドレス' + item_index);
      }
    });
});

$(document).on('click', '.js-btn-export-shop-info', function (e) {
  e.preventDefault();

  let checked_shop = localStorage.getItem("checked_shop");

  let existed_setting_emails = $(this).data('existed-setting-emails');

  if (existed_setting_emails) {
    if (checked_shop) {
      $('.js-export-shop-info-modal').modal('show');
    } else {
      $('.js-not-select-shop-msg-modal').modal('show');
    }
  } else {
    $('label.js-label-notice-msg').html('マスターメニューのメールアドレス設定画面でメールを設定してください');
    $('.js-not-select-shop-msg-modal').modal('show');
  }
});

$(document).on('click', '.js-add-shop-products-btn', function (e) {
  e.preventDefault();

  update_added_product_ids_to_dom();

  $('.js-add-shop-products-modal').modal('show');
});

function update_added_product_ids_to_dom() {
  let items = $('#js-shop-products-table tbody#shop-products').find('tr.product-item:not([style*="display: none"])');
  let product_ids = [];

  for (let i = 0; i < items.length; i++) {
    let p_id = $(items[i]).data("item-id");
    if (p_id != null) {
      product_ids.push(p_id);
    }
  }

  $('.js-added-product-ids').val(product_ids);
}

$(document).on('click', '.js-append-shop-products-checked-btn', function (e) {
  e.preventDefault();

  let items = $('#js-search-results input[value="not_add"]:checked');

  for (let i = 0; i < items.length; i++) {
    $('#js-shop-products-table #js-link-to-add-item a').click();

    let item_row = $(items[i]).parents('tr.js-item-not-add');
    let product_id = $(item_row).data("item-id");
    let shop_product_items = $('#js-shop-products-table tbody#shop-products').find('tr.product-item');
    let inserted_item = shop_product_items[shop_product_items.length - 1];

    $(inserted_item).find('.js-product-id-col').val(product_id);
    $(inserted_item).find('td.js-exist-client-product-col').html($(item_row).find('td.js-exist-client-product-col').html());
    $(inserted_item).find('td.js-product-display-name-col').html($(item_row).find('td.js-product-display-name-col').html());
    $(inserted_item).find('td.js-product-code-col').html($(item_row).find('td.js-product-code-col').html());
    $(inserted_item).find('td.js-product-display-price-col').html($(item_row).find('td.js-product-display-price-col').html());

    $(inserted_item).data('item-id', product_id);
  }

  close_modal_add_shop_products();
});

$(document).on('click', '.js-close-modal-add-shop-products', function (e) {
  e.preventDefault();

  close_modal_add_shop_products();
});

function close_modal_add_shop_products() {
  $('.js-add-shop-products-modal').modal('hide');
  reset_shop_products_search_modal();

  $('.js-source-clients').select2({
    placeholder: "得意先名",
    language: { noResults: function() { return "見つかりません"; } }
  });
}

function reset_shop_products_search_modal() {
  $('.js-add-shop-products-modal input.input-field').val("");
  $('.js-add-shop-products-modal').find('#js-search-results table tbody').html("");
  $('.js-add-shop-products-modal').find('#js-search-results .paginate-row').html("");
}
