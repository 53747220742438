import $ from 'jquery';
$(document).on('ready turbolinks:load', function () {
  let checked_bill = localStorage.getItem("checked_bill");

  if (checked_bill) {
    $('button.js-print-checked-bills-btn').prop('disabled', false);

    if (checked_bill == 'check_all') {
      var checked_bills = $('input[name=checkbox-bill]');
      for (let i = 0; i < checked_bills.length; i++) {
        $(checked_bills[i]).prop('checked', true);
      }
    } else {
      let checked_bill_ids = checked_bill.split(",");
      var checked_bills = $('input[name=checkbox-bill]');
      for (let i = 0; i < checked_bills.length; i++) {
        if (checked_bill_ids.includes($(checked_bills[i]).val())) {
          $(checked_bills[i]).prop('checked', true);
        }
      }
    }
  } else {
    $('button.js-print-checked-bills-btn').prop('disabled', true);
  }

  $('.js-checkbox-bill').change(function() {
    var is_checked_all = false;

    if(this.checked) {
      $('button.js-print-checked-bills-btn').prop('disabled', false);

      if ($(this).val() == "check_all") {
        is_checked_all = true;

        var checked_bills = $('input[name=checkbox-bill]');
        for (let i = 0; i < checked_bills.length; i++) {
          $(checked_bills[i]).prop('checked', true);
        }
      } else {
        var current_bill_id = $(this).val();
        var bill_ids_storage = localStorage.getItem("checked_bill");
        var arr_bill_ids_storage = [];

        if (bill_ids_storage != 'check_all') {
          if (bill_ids_storage) {
            arr_bill_ids_storage = bill_ids_storage.split(",");
          }
          arr_bill_ids_storage.push(current_bill_id);
          localStorage.setItem("checked_bill", arr_bill_ids_storage.join(","));
        }
      }
    } else {
      if ($(this).val() == "check_all") {
        is_checked_all = false;
        localStorage.setItem("checked_bill", "");

        var checked_bills = $('input[name=checkbox-bill]');
        for (let i = 0; i < checked_bills.length; i++) {
          $(checked_bills[i]).prop('checked', false);
        }
      } else {
        var current_bill_id = $(this).val();
        var bill_ids_storage = localStorage.getItem("checked_bill");

        if (bill_ids_storage != 'check_all') {
          var arr_bill_ids_storage = bill_ids_storage.split(",");
          let index = arr_bill_ids_storage.indexOf(current_bill_id);

          if (index > -1) { // only splice array when item is found
            arr_bill_ids_storage.splice(index, 1); // 2nd parameter means remove one item only
          }

          localStorage.setItem("checked_bill", arr_bill_ids_storage.join(","));
        }
      }
    }

    if (is_checked_all) {
      localStorage.setItem("checked_bill", "check_all");
    } else {
      var bill_ids_storage = localStorage.getItem("checked_bill");

      if (bill_ids_storage) {
        $('button.js-print-checked-bills-btn').prop('disabled', false);
      } else {
        $('button.js-print-checked-bills-btn').prop('disabled', true);
      }
    }
  });
  initDateRangePicker();

  $('#js-submit-search-bill').on('click', function(e){
    e.preventDefault();

    var startDate = $('#bill_end_date_gteq').val();
    var endDate = $('#bill_end_date_lteq').val();

    if (startDate == '' || endDate == '') {
      $('#warning-select-range-date-text').text('2日を選択してください');
      $('#warning-select-range-date').modal('show');
    } else {
      $(this).parents('form').submit();
    }
  });
});

function initDateRangePicker() {
  $(document).on('shown.bs.modal', '.js-payment-list-modal', function() {
    var input_dates = $('.js-payment-list-modal').find('.singledate');

    for (let i = 0; i < input_dates.length; i++) {
      let elem_bottom = $(window).height() - $(input_dates[i]).offset().top - $(input_dates[i]).height();

      var daterangepicker_options = {
        locale: ja_date_locale,
        singleDatePicker: true,
        autoUpdateInput: true,
        autoApply: true
      }

      if (elem_bottom < 250) {
        daterangepicker_options['drops'] = 'up';
      }

      $(input_dates[i]).daterangepicker(daterangepicker_options);
    }
  });
  $(document).on('shown.bs.modal', '.js-new-payment-modal', function() {
    $('.singledate').daterangepicker({
      locale: ja_date_locale,
      singleDatePicker: true,
      autoUpdateInput: true,
      autoApply: true,
      drops: 'auto'
    });
  });

  $('.bill-end-date').daterangepicker({
    locale: ja_date_locale,
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true,
    drops: 'auto'
  });

  $('#bill_end_date_gteq').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('YYYY/MM/DD'));
    handleDateRangePickerApply(ev, picker);
  });

  $('#bill_end_date_lteq').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('YYYY/MM/DD'));
    handleDateRangePickerApply(ev, picker);
  });

  $('#bill_end_date_lteq').on('input', function() {
    var value = $(this).val();
    var cleanedValue = value.replace(/[^0-9\/]/g, '');
    $(this).val(cleanedValue);
});
}

function handleDateRangePickerApply(ev, picker) {
  var startDate = $('#bill_end_date_gteq').val();
  var endDate = $('#bill_end_date_lteq').val();

  if (startDate && endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    var today = new Date().toISOString().split('T')[0].replace(/-/g, '/');
    var diffInDays = Math.floor((end - start) / (1000 * 60 * 60 * 24));

    if (diffInDays > 30) {
      $('#warning-select-range-date-text').text('最大 30 日までしか選択できません。');
      $(ev.target).val(today);
      picker.setStartDate(today);
      picker.setEndDate(today);
      $('#warning-select-range-date').modal('show');
    }
  }
}
