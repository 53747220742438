import $ from 'jquery';
$(document).on('ready turbolinks:load', function () {
  let current_pathname = location.pathname;
  const regex = /^\/clients\/(\d+)\/edit$/; 
  if (current_pathname.match(regex)) {
    let bill_target_type_input = 'input[type="radio"][name="client[bill_target_type]"]';

    $(bill_target_type_input).change(function () {
      $('#warning-switch-bill-target-type').modal('show');
    });
  }
});