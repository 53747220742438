import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

$( document ).on('turbolinks:load', function() {
  handleSelectClientProduct();

  $('.js-source-clients').select2({
    placeholder: "得意先名",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-select-category-product-type').select2({
    placeholder: "商品タイプ",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-shops').select2({
    placeholder: "お店",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-products').select2({
    placeholder: "商品名",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-categories').select2({
    placeholder: "商品カテゴリ",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-routes').select2({
    placeholder: "ルート",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-doughs').select2({
    placeholder: "生地",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-driver').select2({
    placeholder: "運転者",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-users').select2({
    placeholder: "担当",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-stock-doughs').select2({
    placeholder: "生地選択",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-materials').select2({
    placeholder: "原材料",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-select-type-of-sheet').select2({
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-select-report-time-type').select2({
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-select-report-time-type-pie').select2({
    language: { noResults: function() { return "見つかりません"; } }
  });

  $(document).on('change', '.js-source-driver', function () {
    var urlEdit = $(this).closest('.row').find('.control-icons .btn-edit');
    // var newUrl = urlEdit.attr('href').replace(/user_id(\S+)/g, 'user_id=' + this.value);
    var newUrl = `${urlEdit.attr('href')}&user_id=${this.value}`;
    urlEdit.attr('href', newUrl);
    urlEdit.removeClass('disabled')
  })

  $('.js-select-product-unit-type').select2({
    placeholder: "単位",
    minimumResultsForSearch: Infinity
  });

  $('.js-select-product-sell-status').select2({
    placeholder: "終売",
    minimumResultsForSearch: Infinity
  });

  $('.js-select-produce-statuses').select2({
    minimumResultsForSearch: Infinity,
    placeholder: "状態"
  });

  $('.js-select-order-items-statuses').select2({
    minimumResultsForSearch: Infinity,
    placeholder: "状態"
  });

  $('.js-select-user-type').select2({
    placeholder: "タイプ",
    minimumResultsForSearch: Infinity
  });

  $('.js-select-products-expired-date').select2({
    placeholder: "消費期限",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-select-material-raw-type').select2({
    placeholder: "原材料タイプ",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-sources-material-categories').select2({
    placeholder: "原材料カテゴリー",
    language: { noResults: function() { return "見つかりません"; } }
  });

  $('.js-source-line-selector-ajax').select2({
    language: { noResults: function () { return "見つかりません"; }}
  });

  $('.js-source-pie-selector-ajax').select2({
    language: { noResults: function () { return "見つかりません"; }}
  });

  $('.js-temperature-select').select2({
    minimumResultsForSearch: Infinity
  });

  $('.js-temperature-select').on('select2:open', function(e) {
    setTimeout(function() {
      $('#select2-deliver_order_temperature-results').scrollTop(210);
    }, 100);
  });
});

$(document).on('turbolinks:before-cache', function() {     // this approach corrects the select 2 to be duplicated when clicking the back button.
  $('.js-source-clients').select2('destroy');
  $('.js-source-shops').select2('destroy');
  $('.js-source-products').select2('destroy');
  $('.js-source-categories').select2('destroy');
  $('.js-source-routes').select2('destroy');
  $('.js-source-doughs').select2('destroy');
  $('.js-source-driver').select2('destroy');
  $('.js-source-users').select2('destroy');
  $('.js-source-stock-products').select2('destroy');
  $('.js-source-materials').select2('destroy');
  $('.js-select-material-raw-type').select2('destroy');
  $('.js-sources-material-categories').select2('destroy');
  $('.js-source-line-selector-ajax').select2('destroy');
  $('.js-source-pie-selector-ajax').select2('destroy');
});

function handleSelectClientProduct() {
  $('.js-select2-client-product-opt').on('select2:select', function (e) {
    var data = e.params.data;
    let product_id = "";
    let client_id = "";

    if ($(e.currentTarget).hasClass('js-source-clients')) {
      client_id = data.id;
      product_id = $('select.js-source-products').val();
    } else {
      product_id = data.id;
      client_id = $('select.js-source-clients').val();
    }
    var current_form = $(e.currentTarget).parents('form');

    if (product_id != "" && client_id != "") {
      $.ajax({
        url: '/client_products/find_record',
        method: 'POST',
        data: { client_id: client_id, product_id: product_id, 'authenticity_token': $('meta[name="csrf-token"]').attr('content') }
      }).done(function(data) {
        if (data != null) {
          $(current_form).find('input.js-client-product-special-name').val(data.special_product_name);
          $(current_form).find('input.js-client-product-price').val(data.price);
        } else {
          $(current_form).find('input.js-client-product-special-name').val("");
          $(current_form).find('input.js-client-product-price').val("");
        }
      });
    }
  });
}
