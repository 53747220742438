// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap"
import "./daterangepicker.min.js"
import "../stylesheets/application.scss"

require("jquery")
require("jquery-ui")
require("@nathanvda/cocoon")
require("jquery-ui/ui/widgets/sortable")
// require("jquery-ui/ui/effects/effect-highlight")
import Highcharts from 'highcharts'

import * as bootstrap from 'bootstrap'
import "bootstrap-datepicker"

const images = require.context('../images', true)

import "./common.js"
import "./custom.js"
import "./popover_custom.js"
import "./select2_common.js"
import "./nested_form_custom.js"
import "./orders.js"
import "./products.js"
import "./delivers.js"
import "./shops.js"
import "./materials.js"
import "./doughs.js"
import "./charts.js"
import "./eliminates.js"
import "./print_orders.js"
import "./bills.js"
import "./clients.js"
import "./routes.js"

import JQuery from 'jquery'
window.$ = window.JQuery = JQuery
window.Highcharts = Highcharts;

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})
