import $ from "jquery";

$(document).on('click', '.js-btn-open-edit-eliminate-modal', function (e) {
  e.preventDefault();

  var eliminateId = $(this).data("item-id");
  getEliminateData(eliminateId);
});

$(document).on('click', '.js-close-edit-eliminates-modal', function (e) {
  e.preventDefault();

  $(".js-edit-eliminates-modal").modal("hide");
});

$(document).on('click', '.js-btn-edit-eliminate-ok', function (e) {
  e.preventDefault();

  var eliminateId = $(".js-edit-eliminates-modal").find(".js-eliminate-id").val();
  updateEliminate(eliminateId);
});

function getEliminateData(eliminateId){
  if (eliminateId != "") {
    $.ajax({
      url: '/eliminates/detail',
      method: 'GET',
      data: {
        eliminate_id: eliminateId,
        'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      }
    }).done(function (response) {
      var eliminate = response.data;
      var parentEl = $(".js-edit-eliminates-modal .eliminates-data");

      $(parentEl).find(".js-product-name-eliminate").html(eliminate.product_name);
      $(parentEl).find(".js-produce-created-date").html(eliminate.created_at);
      $(parentEl).find(".js-eliminates-available").val(eliminate.amount_available);
      $(parentEl).find(".js-eliminate-amount").val(eliminate.amount);
      $(parentEl).find(".js-eliminate-reason").val(eliminate.reason);
      $(parentEl).find(".js-produce-amount").html(eliminate.produce_amount);

      console.log(eliminate.produce_amount);
      $(".js-edit-eliminates-modal .js-eliminate-id").val(eliminateId);
      $(".js-edit-eliminates-modal").modal("show");
    });
  }
}

function updateEliminate(eliminateId) {
  var amount = $(".js-edit-eliminates-modal").find(".js-eliminate-amount").val();
  var reason = $(".js-edit-eliminates-modal").find(".js-eliminate-reason").val();

  if (amount == "" || amount == undefined) {
    $(".js-edit-eliminates-modal .js-eliminate-error-field").removeClass("d-none");
    return false;
  } else {
    $(".js-edit-eliminates-modal .js-eliminate-error-field").addClass("d-none");
  }

  if (eliminateId != "") {
    $.ajax({
      url: '/eliminates/update_amount',
      method: 'POST',
      data: {
        eliminate_id: eliminateId,
        amount: amount,
        reason: reason,
        'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      }
    }).done(function (response) {
      var parentEl = $(".eliminates-list").find(`.item-row-${eliminateId}`);
      $(parentEl).find(".js-eliminates-list-amount").html(`${amount}個`);
      $(parentEl).find(".js-eliminates-list-reason").html(reason);

      var err_str = `<div class="alert alert-success text-center js-flash-msg-area">保存に成功しました。</div>`;
      $(".js-edit-eliminates-modal .err-block").html("").append(err_str);
      $('.js-flash-msg-area').fadeIn('fast');

      setTimeout(function () {
        $('.js-flash-msg-area').fadeOut('fast');
        $(".js-edit-eliminates-modal").modal("hide");
      }, 3000);
    });
  }
}
